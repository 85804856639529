nav {
  background-color: #1067b1;
  padding: 0.1rem 1rem;
  box-shadow: 0 1px 5px #5e5e5e;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orientPort {
  display: none;
}

.orientLand {
  display: inline;
}

@media (orientation: portrait) {

  /* nav {
      display: flex;
      flex-direction: column;
      align-items: center; /* Optional: aligns items horizontally centered 
      justify-content: center; /* Optional: aligns items vertically centered 
    } */
  .orientPort {
    display: inline;
  }

  .orientLand {
    display: none;
  }
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0.5rem;
}

nav ul li {
  display: inline;
  margin-right: 1rem;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

nav ul li a:hover {
  text-decoration: underline;
}

nav ul li a.active-link {
  font-weight: bold;
  /* Делает активную ссылку жирной */
}

nav input {
  border: none;
  border-radius: 1rem;
  height: 1.5rem;
  padding: 0 0.5rem;
  background-color: #eaeaea;
  margin: 0.5rem 0;
}