body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  height: 100vh;
  background:
    /* radial-gradient(circle at top center, rgb(255, 255, 255) 50%, transparent 30px) */
    radial-gradient(circle at top left, #fafafa 50%, transparent 31px)
    /* ,radial-gradient(circle at top right, #eaeaea 50%, transparent 31px) */
    , linear-gradient(#eaeaea, #fefefe);

  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-align: left;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto ; 
  gap:10px;
}

.grid-container > div {
  text-align:left;
  /* border: 1px solid red; */
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */
}

@media (orientation: portrait) {
  .grid-container {
    display: block;
  }
  .grid-container > div {
       margin-bottom: 10px;
  }
}

.clear {
  clear: both;
  padding-bottom: 1rem;

}