.grid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    border: 1px solid silver;
    width:fit-content;
    border-radius: 1px;
}

.grid div{
    background-color: white;
    margin: 1px;
    padding: 5px;
    border-bottom: 1px solid silver;
    display: flex;
}

.grid div.head{
    background-color: rgb(225, 225, 225);
    margin: 1px;
    padding: 5px;
    font-weight: bold;
}

.round {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    /* Blue background color, you can change this */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
}