.Visual {
  text-align: center;
  margin-top: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ------------------------------------------ */
.creditCardStyle- {
  height: '85.60mm';
  width: '53.98mm';
  background-color: li;
  border: '1px solid black';
  border-radius: '15px';
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  position: 'relative';
  margin: '0 auto';
} 